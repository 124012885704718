import { StepIconClasskey } from '@material-ui/core';
import moment from 'moment';
import { UserManager } from 'oidc-client';
import {
	ApiNetworkFunctions,
	PagedResponse
} from 'src/networking/api/apiTypes';
import { AuditDetails, getAccountAudits } from 'src/networking/api/audits';
import {
	ClassifierTypes,
	ClassifierView,
	ClassifierViewType,
	TestClassifierResponse,
	CodeusAccount,
	CodeusAccountConfig,
	CodeusAccountDetails,
	CodeusAccountState,
	CodeusAccountStats,
	ConnectorPostRequest,
	DIConnector,
	DIConnectorType,
	DISubscription,
	ESFeedResponse,
	ESScanResponse,
	Feed,
	BulkFeedResponse,
	FeedStatus,
	FeedStatusChangeReason,
	Snapshot,
	User,
	UserAssignment,
	Company,
	UserDetail,
	UserInfo,
	SupportDelegate,
	AccDeleteJob,
	ClassifierCategories,
	AllAccountAssignmentsForUser
} from 'src/types/dataTypes';
import { del, get, HttpVerb, patch, post, put } from './http';
import { 
	ClassifiersResponse, 
	CreatePolicyResponse, 
	GroupsApiResponse, 
	PolicyResponse, 
	UpdatePolicyResponse, 
	UpdatePriorityResponse, 
	UsersDataResponse 
} from 'src/types/policyTypes';

export const DEFAULT_FETCH_OPTIONS: RequestInit = {
	method: 'GET',
	mode: 'cors',
	headers: {
		'Content-Type': 'application/json',
	},
};

export function APIError(response: Response): { response: Response } {
	return {
		response,
	};
}

export const AuthorizationError = function (
	message?: string,
	response?: Response,
): { err: Error; status: number; response: Response } {
	return {
		err: new Error(message || 'Unauthorized request.'),
		status: 401,
		response,
	};
};

export async function sendFormData(
	url: RequestInfo,
	formData: FormData,
	method: HttpVerb = 'PUT',
): Promise<Response> {
	return fetch(url, {
		method: method,
		body: formData,
		mode: 'cors',
		credentials: 'include',
	});
}

/**
 * Both web-ui and admin-ui share the same set of API connections.
 * Because of this, we can make some assumptions about functionality,
 * such as assuming the need to handle de-authorization on 401 errors.
 *
 * This function serves as convenience so that base paths don't have to be passed
 * to every route individually, errors are handled consistently, and
 * responses are in JSON.
 *
 * @param apiBaseUrl The URL for the API
 */
export function makeAPI(
	apiBaseUrl = '/api',
	oidcUserManager: UserManager,
): CodeusAPI {
	const _getAccessToken = async () => {
		const oidcUser = await oidcUserManager.getUser();

		if (!oidcUser) {
			// FIXME : Haaaaaacks
			const rej = new PromiseRejectionEvent('rejectionhandled', {
				promise: null,
				reason: {
					status: 401,
				},
			});

			throw rej.reason;
		}

		return oidcUser?.access_token;
	};

	const _delete = async (
		url: string,
		params = {},
		options?: RequestInit,
		addRootUrl = true,
	): Promise<Response> => {
		const access_token = await _getAccessToken();
		if (access_token)
			DEFAULT_FETCH_OPTIONS.headers[
				'Authorization'
			] = `Bearer ${access_token}`;

		const destination = addRootUrl ? `${apiBaseUrl}${url}` : url;
		const result = await del(destination, {
			...DEFAULT_FETCH_OPTIONS,
			...options,
		});

		// Set if status is 200-299
		if (result.ok) return result;

		throw APIError(result);
	};

	const _get = async (
		url: string,
		params = {},
		options?: RequestInit,
		addRootUrl = true,
		json=true,
	) => {
		const access_token = await _getAccessToken();
		if (access_token)
			DEFAULT_FETCH_OPTIONS.headers[
				'Authorization'
			] = `Bearer ${access_token}`;

		const destination = addRootUrl ? `${apiBaseUrl}${url}` : url;
		const result = await get(destination, params, {
			...DEFAULT_FETCH_OPTIONS,
			...options,
		});

		// Set if status is 200-299
		if (result.ok){
			if( json )
			 return result.json();
			else 
			return result.text();
		} 

		if (result.status === 401) {
			throw AuthorizationError('Unauthorized', result);
		}

		throw APIError(result);
	};

	const _patch = async (
		url: string,
		params = {},
		options?: RequestInit,
		addRootUrl = true,
	) => {
		const access_token = await _getAccessToken();
		if (access_token)
			DEFAULT_FETCH_OPTIONS.headers[
				'Authorization'
			] = `Bearer ${access_token}`;

		const result = await patch(`${apiBaseUrl}${url}`, params, {
			...DEFAULT_FETCH_OPTIONS,
			...options,
		});

		// Set if status is 200-299
		if (result.ok) return result.json();

		if (result.status === 401) {
			throw AuthorizationError('Unauthorized', result);
		}

		throw APIError(result);
	};

	const _post = async (url: string, params = {}, options?: RequestInit) => {
		const access_token = await _getAccessToken();
		if (access_token)
			DEFAULT_FETCH_OPTIONS.headers[
				'Authorization'
			] = `Bearer ${access_token}`;

		const result = await post(`${apiBaseUrl}${url}`, params, {
			...DEFAULT_FETCH_OPTIONS,
			...options,
		});

		// Set if status is 200-299
		if (result.ok) return result.json();

		if (result.status === 401) {
			throw AuthorizationError('Unauthorized', result);
		}

		throw APIError(result);
	};

	const _put = async (url: string, params = {}, options?: RequestInit) => {
		const access_token = await _getAccessToken();
		if (access_token)
			DEFAULT_FETCH_OPTIONS.headers[
				'Authorization'
			] = `Bearer ${access_token}`;

		const result = await put(`${apiBaseUrl}${url}`, params, {
			...DEFAULT_FETCH_OPTIONS,
			...options,
		});

		// Set if status is 200-299
		if (result.ok) return result.json();

		if (result.status === 401) {
			throw AuthorizationError('Unauthorized', result);
		}

		throw APIError(result);
	};

	const _putUpload = async (url: string, data: FormData) => {
		const access_token = await _getAccessToken();
		if (access_token)
			DEFAULT_FETCH_OPTIONS.headers[
				'Authorization'
			] = `Bearer ${access_token}`;

		const fullUrl = new URL(`${apiBaseUrl}${url}`);
		const params = new URLSearchParams(fullUrl.search.slice(1));

		// add access token to query params
		params.append('access_token', access_token);
		fullUrl.search = params.toString();
		const result = await sendFormData(fullUrl.href, data);

		// Set if status is 200-299
		if (result.ok) return result.json();

		if (result.status === 401) {
			throw AuthorizationError('Unauthorized', result);
		}

		throw APIError(result);
	};

	const networkFunctions: ApiNetworkFunctions = {
		del: _delete,
		get: _get,
		patch: _patch,
		post: _post,
		put: _put,
	};

	return {
		...networkFunctions,
		async getAccessToken() {
			return await _getAccessToken();
		},
		async addConnectorToAccountSubscriptions(
			accountId,
			subscriptionId,
			data = null,
			options = {},
		) {
			const promise = _post(
				`/accounts/${accountId}/subscriptions/${subscriptionId}/connectors`,
				data,
				options,
			).then((response) => {
				return response.data;
			});
			return promise;
		},
		async addClassifierView(accountId, type) {
			if (!accountId) throw Error('No account ID provided');
			if (!type) throw Error('No type provided');

			const promise = _post(`/accounts/${accountId}/classifiers/views`, {
				type,
			}).then((response) => {
				return response.data;
			});

			return promise;
		},
		async addUserAssignment(accountId, assignment, options) {
			if (!accountId) throw new Error('Invalid account ID');

			const promise = _post(
				`/accounts/${accountId}/assignments`,
				assignment,
				options,
			).then((response) => {
				return response.data;
			});

			return promise;
		},
		async deleteClassifierView(accountId, viewId) {
			if (!accountId) throw new Error('Invalid account ID');
			if (!viewId) throw new Error('Invalid view ID');

			const promise = _delete(
				`/accounts/${accountId}/classifiers/textFileViews/${viewId}`,
			).then((response) => {
				return true;
			});

			return promise;
		},
		async deleteConnectorFromAccount(accountId, connectorId, options) {
			if (!accountId) throw new Error('Invalid account ID');

			const promise = _delete(
				`/accounts/${accountId}/connectors/${connectorId}`,
				{},
				options,
			).then(() => true);

			return promise;
		},
		async patchConnectorForAccount(accountId, connectorId,operation, options) {
			if (!accountId) throw new Error('Invalid account ID');

			const promise = _patch(
				`/accounts/${accountId}/connectors/${connectorId}`,
				operation,
				options,
			).then(() => true);

			return promise;
		},
		async deleteUserAssignment(accountId, assignment, options) {
			if (!accountId) throw new Error('Invalid account ID');

			const promise = _delete(
				`/accounts/${accountId}/assignments/${assignment.subject}/roles/${assignment.role}`,
				{},
				options,
			);

			return promise;
		},
		async getAccountAssignment(accountId,filters, options) {
			if (!accountId) throw new Error('Invalid account ID');

			const promise = _get(
				`/accounts/${accountId}/assignments`,
				filters,
				options,
			);

			return promise;
		},
		async getAccountClassifierTypes(accountId) {
			if (!accountId) throw new Error('Invalid account ID');
			const promise = _get(
				`/accounts/${accountId}/classifiers/views/types`,
			).then((response) => {
				return response.data;
			});

			return promise;
		},
		async getAccountClassifierViews(accountId) {
			if (!accountId) throw new Error('Invalid account ID');
			const promise = _get(
				`/accounts/${accountId}/classifiers/views`,
			).then((response) => {
				return response.data;
			});

			return promise;
		},
		async getAccountConfig(accountId, options = null) {
			if (!accountId) throw new Error('Invalid account ID');
			const promise = _get(
				`/accounts/${accountId}/config`,
				null,
				options,
			).then((response) => {
				return response.data;
			});
			return promise;
		},
		async getAccountTierState(accountId, options = null) {
			if (!accountId) throw new Error(`Invalid account ID: ${accountId}`);
			const promise = _get(
				`/accounts/${accountId}/state`,
				{},
				options,
			).then((response) => {
				return response.data;
			});
			return promise;
		},
		async getDefaultReport(
			accountId,
			groupBy,
			sortOrder,
			absoluteDateRangeStart,
			absoluteDateRangeEnd,
			period,
			options = null,
		) {
			if (!accountId) throw new Error(`Invalid account ID: ${accountId}`);
			const params = new URLSearchParams({
				absoluteDateRangeStart: moment(absoluteDateRangeStart)
					.utc()
					.format('YYYY-MM-DD'),
				absoluteDateRangeEnd: moment(absoluteDateRangeEnd).utc().format('YYYY-MM-DD'),
				period,
				groupBy
			});
			const promise: any = _get(
				`/accounts/${accountId}/reporting/default-report`,
				params,
				options,
			).then((response) => {
				return response;
			});
			return promise;
		},
		async getReportList(accountId, options = null) {
			if (!accountId) throw new Error(`Invalid account ID: ${accountId}`);
			const promise: any = _get(
				`/accounts/${accountId}/reporting/reports`,
				{},
				options,
			).then((response) => {
				return response.data;
			});
			return promise;
		},
		async addReport(accountId, body, options = null) {
			if (!accountId) throw new Error(`Invalid account ID: ${accountId}`);
			const promise: any = _post(
				`/accounts/${accountId}/reporting/reports`,
				body,
				options,
			).then((response) => {
				return response.data;
			});
			return promise;
		},
		async updateReport(accountId, reportId, body, options = null) {
			if (!accountId) throw new Error(`Invalid account ID: ${accountId}`);
			const promise: any = _patch(
				`/accounts/${accountId}/reporting/reports/${reportId}`,
				body,
				options,
			).then((response) => {
				return response.data;
			});
			return promise;
		},
		async getReportById(
			accountId,
			reportId,
			absoluteDateRangeStart,
			absoluteDateRangeEnd,
			period,
			options = null,
		) {
			if (!accountId) throw new Error(`Invalid account ID: ${accountId}`);
			const params = new URLSearchParams({
				absoluteDateRangeStart: moment(absoluteDateRangeStart)
					.utc()
					.format('YYYY-MM-DD'),
				absoluteDateRangeEnd: moment(absoluteDateRangeEnd).utc().format('YYYY-MM-DD'),
				period,
			});
			const promise: any = _get(
				`/accounts/${accountId}/reporting/reports/${reportId}`,
				params,
				options,
			).then((response) => {
				return response;
			});
			return promise;
		},
		async deleteReportById(
			accountId,
			reportId,
			options = null,
		) {
			if (!accountId) throw new Error(`Invalid account ID: ${accountId}`);
			const promise: any = _delete(
				`/accounts/${accountId}/reporting/reports/${reportId}`,
				{},
				options,
			).then((response) => {
				return response;
			});
			return promise;
		},
		async saveAccountConfig(accountId, params, options = null) {
			const promise = _patch(
				`/accounts/${accountId}/config`,
				params,
				options,
			).then((r) => r.data);
			return promise;
		},
		async getAccountConnectors(id, type = '', options = {}) {
			const promise = _get(
				`/accounts/${id}/connectors`,
				{ type },
				options,
			).then((res) => res.data);

			return promise;
		},
		async getAccountConnectorsForSubscription(id,subId, type = '', options = {}) {
			const promise = _get(
				`/accounts/${id}/subscriptions/${subId}/connectors`,
				{ type },
				options,
			).then((res) => res.data);

			return promise;
		},
		async getAccountUsers(id, filters = null, options = {}) {
			const filter = filters ? { [filters.type]: filters.value } : {};
			const promise = _get(`/accounts/${id}/users`, filter, options).then(
				(res) => res.data,
			);

			return promise;
		},
		async getAccounts(name, options = null) {
			const promise = _get('/accounts', { name }, options).then(
				(res) => res.data[0],
			);
			return promise;
		},
		
		async updateAccount(
			accountId,
			operation,
			options = {},
		) {
			const promise = _patch(
				`/accounts/${accountId}`,
				operation,
				options,
			).then((res) => res.data);
			return promise;
		},
		async deleteAccount(id, body, options = null) {
			const promise = _post(`/accounts/${id}/delete`, body, options).then(
				(res) => res.data[0],
			);
			return promise;
		},
		async getDeleteAccJob(id, options = null) {
			const promise = _get(`/accounts/${id}/delete`, { }, options).then(
				(res) => res.data,
			);
			return promise;
		},
		async cancelDeleteAccJob(id, options = null) {
			const promise = _delete(`/accounts/${id}/delete`, { }, options).then(
				(res) => res,
			);
			return promise;
		},
		getAccountAudits: (accountId: string, pageNumber) =>
			getAccountAudits(networkFunctions, accountId, 100, pageNumber),
		async getUserAccounts(id = 'current', options = null) {
			const promise = _get(`/users/${id}/accounts`, options).then(
				(res) => res.data,
			);
			return promise;
		},
		
		async getUserAssignments(userId, options) {
			const promise = _get(
				`/users/${userId}/assignments`,
				new URLSearchParams({PageSize: `1000`}),
				options,
			).then((res) => res.data);
			return promise;
		},
		async getCompanies(search, options) {
			const promise = _get(
				`/companies`,
				new URLSearchParams({PageSize: `1000`,search}),
				options,
			).then((res) => res.data);
			return promise;
		},
		async getCompanyById(companyId, options) {
			const promise = _get(
				`/companies/${companyId}`,
				new URLSearchParams({PageSize: `1000`}),
				options,
			).then((res) => res.data);
			return promise;
		},
		async getCompanyAccounts(companyId, options) {
			const promise = _get(
				`/companies/${companyId}/accounts`,
				null,
				options,
			).then((res) => res.data);
			return promise;
		},
		async addAccountToCompany(companyId,accountName, options) {
			const promise = _post(
				`/companies/${companyId}/accounts`,
				{
					tenantType: "Microsoft",
					name: accountName
				  },
				options,
			).then((res) => res.data);
			return promise;
		},
		async getUserAccountAssignments(userId, accountId,options) {
			const promise = _get(
				`/users/${userId}/accounts/${accountId}/assignments`,
				new URLSearchParams({PageSize: `1000`}),
				options,
			).then((res) => res.data);
			return promise;
		},
		async getAllAccountAssignmentsForUser(userId,options) {
			const queryParams = new URLSearchParams();
			queryParams.append('operation', 'any');
			queryParams.append('api-version', '1.0');

			const promise = _get(
				`/users/${userId}/accounts/assignments`,
				queryParams,
				options,
			).then((res) => res.data);
			return promise;
		},
		async getUserInfo(options) {
			const promise = _get(`/info`, {}, options).then((res) => res.data);
			return promise;
		},
		/**
		 * Gets information about the user, if one is currently
		 * logged in to the API.
		 */
		async getLoggedInUser(options = null) {
			const promise = _get('/users/current', {}, options).then(
				(res) => res.data,
			);
			return promise;
		},
		async getAccountFeeds(
			accountId,
			PageNumber = 0,
			PageSize = 100,
			Classifications = [],
			options = null,
		) {
			const params = new URLSearchParams({
				PageNumber: `${PageNumber}`,
				PageSize: `${PageSize}`,
				orderBy: '-created',
			});

			if (Classifications.length) {
				Classifications.forEach((classification) => {
					params.append('Classifications', classification);
				});
			}

			const promise = _get(
				`/accounts/${accountId}/feeds`,
				params,
				options,
			).then((res) => res.data);

			return promise;
		},
		async getAccountESFeeds(
			accountId,
			startDate,
			endDate,
			searchText =  '',
			searchField = '',
			selectedFilters?: Array<any>,
			PageNumber=1,
			PageSize=100,
			sortBy = 'lastUpdated' ,
			sortOrder = 'desc',
			status = 'Open',
			options = null,
		) {
			if( sortOrder == 'desc' ){
				sortOrder = 'dsc';
			}
			const params = new URLSearchParams({
				pageSize: `${PageSize}`,
				pageNumber: `${PageNumber - 1}`,
				startTime : moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				endTime :  moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				searchText,
				searchField,
				orderBy : sortBy == '' ? 'lastUpdated': sortBy ,
				sortOrder ,
				status
			});
			selectedFilters.forEach( filter => {
				params.append(filter.name, filter.value);
				
			})
			
			const promise = _get(
				`/accounts/${accountId}/esfeeds`,
				params,
				options,
			).then((res) => res);

			return promise;
		},
		async getClassifierCategories(
			accountId,
			options = null,
		) {
			const params = new URLSearchParams({
				disabled : 'false'
			});
			
			const promise = _get(
				`/accounts/${accountId}/classifiers/category`,
				params,
				options,
			).then((res) => res);

			return promise;
		},
		
		async getAccountESScans(
			accountId,
			startDate,
			endDate,
			searchText =  '',
			searchField = '',
			selectedFilters?: Array<string>,
			PageNumber=1,
			PageSize=100,
			sortBy = 'scanDate' ,
			sortOrder = 'desc',
			options = null,
		) {
			if( sortOrder == 'desc' ){
				sortOrder = 'dsc';
			}
			const params = new URLSearchParams({
				pageSize: `${PageSize}`,
				pageNumber: `${PageNumber - 1}`,
				startTime : moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				endTime :  moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				searchText,
				searchField,
				orderBy : sortBy == '' ? 'scanDate': sortBy ,
				sortOrder 
			});
			let statusParams =  []
			selectedFilters.forEach( filter => {
				let category = filter.split(":")[0].trim();
				let value = filter.split(":")[1].trim();
				switch( category ){
					case 'Status' : params.append('states',value);break;
					case 'Platform' : params.append('platforms',value);break;
					case 'ItemId' : params.append('itemId',value);break;
					case 'Decision' :  statusParams.push(value); 
				}
			})
			
			if( statusParams.length == 1) {
				if( statusParams[0] == 'Violation'){
					params.append('isEvent', 'true');
				}else{
					params.append('isEvent', 'false');
				}
				
			}
			const promise = _get(
				`/accounts/${accountId}/scanlogs`,
				params,
				options,
			).then((res) => res);

			return promise;
		},

		async getAccountDownloadESScans(
			accountId,
			startDate,
			endDate,
			searchText =  '',
			searchField = '',
			selectedFilters?: Array<string>,
			sortBy = 'scanDate' ,
			sortOrder = 'desc',
			options = null,
		) {
			if( sortOrder == 'desc' ){
				sortOrder = 'dsc';
			}
			const params = new URLSearchParams({
				pageSize: '10000',
				pageNumber: '0',
				startTime : moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				endTime :  moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				searchText,
				searchField,
				orderBy : sortBy == '' ? 'scanDate': sortBy ,
				sortOrder 
			});
			let statusParams =  []
			selectedFilters.forEach( filter => {
				let category = filter.split(":")[0].trim();
				let value = filter.split(":")[1].trim();
				switch( category ){
					case 'Status' : params.append('states',value);break;
					case 'Platform' : params.append('platforms',value);break;
					case 'Decision' :  statusParams.push(value); 
				}
			})
			
			if( statusParams.length == 1) {
				if( statusParams[0] == 'Violation'){
					params.append('isEvent', 'true');
				}else{
					params.append('isEvent', 'false');
				}
				
			}
			
			const promise = _get(
				`/accounts/${accountId}/scanlogs/export`,
				params,
				options,
				true,
				false
			).then((res) => res);

			return promise;
		},
		async getAccountAggregateESScans(
			accountId,
			startDate,
			endDate,
			searchText =  '',
			searchField = '',
			selectedFilters?: Array<string>,
			options = null,
		) {
			const params = new URLSearchParams({
				startTime : moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				endTime :  moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				searchText,
				searchField,
			});
			let statusParams =  []
			selectedFilters.forEach( filter => {
				let category = filter.split(":")[0].trim();
				let value = filter.split(":")[1].trim();
				switch( category ){
					case 'Status' : params.append('states',value);break;
					case 'Platform' : params.append('platforms',value);break;
					case 'Decision' :  statusParams.push(value); 
				}
			})
			
			if( statusParams.length == 1) {
				if( statusParams[0] == 'Violation'){
					params.append('isEvent', 'true');
				}else{
					params.append('isEvent', 'false');
				}
				
			}

			const promise = _get(
				`/accounts/${accountId}/scanlogs/aggregate`,
				params,
				options,
			).then((res) => res);

			return promise;
		},
		async getAccountESAudit(
			accountId,
			startDate,
			endDate,
			searchText =  '',
			searchField = '',
			selectedFilters?: Array<string>,
			PageNumber=1,
			PageSize=100,
			sortBy = 'creationTime' ,
			sortOrder = 'desc',
			options = null,
		) {
			if( sortOrder == 'desc' ){
				sortOrder = 'dsc';
			}
			const params = new URLSearchParams({
				pageSize: `${PageSize}`,
				pageNumber: `${PageNumber - 1}`,
				startTime : moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				endTime :  moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				searchText,
				searchField,
				orderBy : sortBy == '' ? 'creationTime': sortBy ,
				sortOrder 
			});
			selectedFilters.forEach( filter => {
				let category = filter.split(":")[0].trim();
				let value = filter.split(":")[1].trim();
				switch( category ){
					case 'Category' : params.append('categories',value);break;
				}
			})
			
			const promise = _get(
				`/accounts/${accountId}/audits/v2`,
				params,
				options,
			).then((res) => res);

			return promise;
		},

		async getAccountDownloadESAudit(
			accountId,
			startDate,
			endDate,
			searchText =  '',
			searchField = '',
			selectedFilters?: Array<string>,
			sortBy = 'creationTime' ,
			sortOrder = 'desc',
			options = null,
		) {
			if( sortOrder == 'desc' ){
				sortOrder = 'dsc';
			}
			const params = new URLSearchParams({
				pageSize: '10000',
				pageNumber: '0',
				startTime : moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				endTime :  moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				searchText,
				searchField,
				orderBy : sortBy == '' ? 'creationTime': sortBy ,
				sortOrder 
			});
			selectedFilters.forEach( filter => {
				let category = filter.split(":")[0].trim();
				let value = filter.split(":")[1].trim();
				switch( category ){
					case 'Category' : params.append('categories',value);break;
				}
			})
			
			
			const promise = _get(
				`/accounts/${accountId}/audits/export`,
				params,
				options,
				true,
				false
			).then((res) => res);

			return promise;
		},
		async getAccountAggregateESAudit(
			accountId,
			startDate,
			endDate,
			searchText =  '',
			searchField = '',
			selectedFilters?: Array<string>,
			options = null,
		) {
			const params = new URLSearchParams({
				startTime : moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				endTime :  moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				searchText,
				searchField,
			});
			selectedFilters.forEach( filter => {
				let category = filter.split(":")[0].trim();
				let value = filter.split(":")[1].trim();
				switch( category ){
					case 'Category' : params.append('categories',value);break;
				}
			})
			

			const promise = _get(
				`/accounts/${accountId}/audits/v2/aggregate`,
				params,
				options,
			).then((res) => res);

			return promise;
		}, 
		async getAccountDownloadESFeeds(
			accountId,
			startDate,
			endDate,
			searchText =  '',
			searchField = '',
			selectedFilters?: Array<any>,
			sortBy?:string,
			sortOrder?:string,
			status = 'Open',
			options = null,
		) {
			
			const params = new URLSearchParams({
				pageNumber : '0',
				pageSize : '10000',
				startTime : moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				endTime :  moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				searchText,
				searchField,
				orderBy : sortBy == '' ? 'lastUpdated': sortBy ,
				sortOrder ,
				status
			});
			selectedFilters.forEach( filter => {
				params.append(filter.name, filter.value);
			})
			
			const promise = _get(
				`/accounts/${accountId}/esfeeds/export`,
				params,
				options,
				true,
				false
			).then((res) => res);

			return promise;
		},
		async getPolicyEngineFeeds(accountId, searchText = '') {
			const promise = _get(
				`/accounts/${accountId}/policies?name=${searchText}&api-version=1.0`,
				{},
				null,
				true,
				true
			).then((res) => res);

			return promise;
		},
		async getConsent(accountId) {
			const promise = _get(
				`/accounts/${accountId}/groups/consent?api-version=1.0`,
				{},
				null,
				true,
				true
			).then((res) => res);

			return promise;
		},
		async updatePolicyEngineFeedPriorities(accountId, reqPayload, options = null) {
			const promise = _put(
				`/accounts/${accountId}/policies/priority?api-version=1.0`,
				reqPayload,
				options
			).then((res) => res);

			return promise;
		},
		async deletePolicyFeed(accountId, policyId) {
			if (!accountId) throw new Error('Invalid account ID');
			if (!policyId) throw new Error('Invalid policy ID');

			const promise = _delete(
				`/accounts/${accountId}/policies/${policyId}?api-version=1.0'`,
			).then((response) => {
				return true;
			});

			return promise;
		},
		async updatePolicyFeed(accountId, reqPayload, policyId, options = null) {
			const promise = _put(
				`/accounts/${accountId}/policies/${policyId}?api-version=1.0`,
				reqPayload,
				options
			).then((res) => res);

			return promise;
		},

		async getPolicyGroups(accountId, query = '') {
			let queryString
			query.length > 0 ? queryString = `groups?name=${query}&`: queryString = `groups?`;

			const promise = _get(
				`/accounts/${accountId}/${queryString}api-version=1.0`,
				{},
				null,
				true,
				true
			).then((res) => res);

			return promise;
		},
		async getPolicyUsers(accountId, query = '') {
			let queryString = `users?`;
			const searchFields = ['name', 'email'];

			if(query.length > 0) {
				const [searchField, searchText] = query.split(":").map(str => str.trim());

				if(searchText == null || searchFields.indexOf(searchField) === -1) {
					queryString += `name=${query}&`
				}else {
					queryString += `${searchField}=${searchText}&`
				}
			}
			
			const promise = _get(
				`/accounts/${accountId}/${queryString}api-version=1.0`,
				{},
				null,
				true,
				true
			).then((res) => res);

			return promise;
		},
		async getPolicyClassifiers(accountId) {
			const promise = _get(
				`/accounts/${accountId}/classifiers/views`,
				{},
				null,
				true,
				true
			).then((res) => res);

			return promise;
		},
		async createPolicy(accountId, payload) {
			if (!accountId) throw new Error('Invalid account ID');

			const promise = _post(
				`/accounts/${accountId}/policies?api-version=1.0`,
				payload,
				{},
			).then((response) => {
				return response.data;
			});

			return promise;
		},
		async submitRedactionFeedback(previewUrl, payload) {
			if (previewUrl.length === 0) throw new Error('Invalid feedback Submission');

			const promise = _post(
				`${previewUrl}/feedback`,
				payload,
				{},
			).then((response) => {
				return response;
			});

			return promise;
		},

		async getFeedbackSubmittedData(itemId, findingId) {
			if (itemId.length === 0 || findingId.length === 0) throw new Error('Invalid feedback Submission');

			const promise = _get(
				`/items/${itemId}/findings/${findingId}/feedback`,
				{},
				null,
				true,
				true
			).then((res) => res);

			return promise;
		},

		async getAccountAggregateESFeeds(
			accountId,
			startDate,
			endDate,
			searchText =  '',
			searchField = '',
			selectedFilters?: Array<any>,
			status = 'Open',
			options = null,
		) {
			const params = new URLSearchParams({
				startTime : moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				endTime :  moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') ,
				searchText,
				searchField,
				status
			});
			selectedFilters.forEach( filter => {
				params.append(filter.name, filter.value);
			})

			const promise = _get(
				`/accounts/${accountId}/esfeeds/aggregate`,
				params,
				options,
			).then((res) => res);

			return promise;
		},
		async getFeed(feedId: string, options = {}) {
			return _get(`/feeds/${feedId}`, {}, options);
		},
		async getFeedV2(feedId: string, options = {}) {
			return _get(`/feeds/${feedId}/v2`, {}, options);
		},
		async getFeedSnapshot(feedId: string, options = {}) {
			return _get(`/Feeds/${feedId}/Snapshot`, {}, options);
		},
		async getAccountStats(accountId, options = {}) {
			const promise = _get(
				`/accounts/${accountId}/stats`,
				{},
				options,
			).then((res) => res.data);
			return promise;
		},
		async createSupportDelegateToken(accountId, options = {}) {
			const promise = _post(
				`/users/current/delegates`,
				{},
				options,
			).then((res) => res.data);
			return promise;
		}, 
		async getSupportDelegateToken(accountId, options = {}) {
			const promise = _get(
				`/users/current/delegates`,
				{},
				options,
			).then((res) => {
				return res.data[0];
			});
			return promise;
		},
		async deleteSupportDelegateToken(delegateId, options = {}) {
			const promise = _delete(
				`/users/current/delegates/${delegateId}`,
				{},
				options,
			).then((res) => true);
			return promise;
		},
		async getUserConnectors(id = 'current', type = '', options = {}) {
			const promise = _get(
				`/users/${id}/connectors`,
				{ type },
				options,
			).then((res) => res.data);

			return promise;
		},
		async getUserSubscriptions(id = 'current', options = {}) {
			const promise = _get(
				`/users/${id}/subscriptions`,
				{},
				options,
			).then((res) => res.data);

			return promise;
		},
		async updateTextFileClassifier(
			accountId,
			viewId,
			operation,
			options = {},
		) {
			const promise = _patch(
				`/accounts/${accountId}/classifiers/textFileViews/${viewId}`,
				operation,
				options,
			).then((res) => res.data);
			return promise;
		},
		async updateRegexClassifier(
			accountId,
			viewId,
			operation,
			options = {},
		) {
			const promise = _patch(
				`/accounts/${accountId}/classifiers/customRegexViews/${viewId}`,
				operation,
				options,
			).then((res) => res.data);
			return promise;
		},
		async updateFeed(feedId, status, statusReason, options) {
			const promise = _put(
				`/feeds/${feedId}/status`,
				{ status, statusReason },
				options,
			).then((res) => res.data);
			return promise;
		},
		async updateBulkFeed(feedIds, status, statusReason, options) {
			const promise = _put(
				`/feeds/status`,
				{ ids : feedIds, status, statusReason },
				options,
			).then((res) => res.data);
			return promise;
		},
		async updateUserConfig(userId, operation, options) {
			const promise = _patch(
				`/users/${userId}/config`,
				operation,
				options,
			).then((res) => res.data);

			return promise;
		},
		async updateWordViewClassifier(
			accountId,
			viewId,
			operation,
			options = {},
		) {
			const promise = _patch(
				`/accounts/${accountId}/classifiers/wordsViews/${viewId}`,
				operation,
				options,
			).then((res) => res.data);

			return promise;
		},
		async uploadTextFileView(accountId, viewId, data) {
			const promise = _putUpload(
				`/accounts/${accountId}/classifiers/textFileViews/${viewId}/payload/upload`,
				data,
			).then((res) => res.data);

			return promise;
		},
		async testRegex(accountId, regex, testInput,displayName, tagName) {
			const promise = _post(
				`/accounts/${accountId}/classifiers/customRegexViews/test`,
				{regex,testInput,displayName,tagName},
			).then((res) => res);

			return promise;
		},

		
	};
}

// ------------------------- HERE BE TYPES ------------------------- //

export interface DIResult<T> {
	data: T;
	elapsed: number;
}

export interface DIPaginatedResult<T> extends DIResult<T> {
	pageNumber: number;
	pageSize: number;
	count: number;
	data: T;
	elapsed: number;
}

export interface CodeusAPI extends ApiNetworkFunctions {
	getAccessToken: () => Promise<string>;
	addClassifierView: (
		accountId: string,
		type: ClassifierTypes,
	) => Promise<ClassifierView>;
	addConnectorToAccountSubscriptions: (
		accountId: string,
		subscriptionId: string,
		data: ConnectorPostRequest,
		options?: RequestInit,
	) => Promise<DIConnector>;
	addUserAssignment: (
		accountId: string,
		assignment: Omit<UserAssignment, 'domain' | 'permissions'>,
		options?: RequestInit,
	) => Promise<any>;
	deleteClassifierView: (
		accountId: string,
		viewId: string,
	) => Promise<boolean>;
	deleteConnectorFromAccount: (
		accountId: string,
		connectorId: string,
		options?: RequestInit,
	) => Promise<boolean>;
	patchConnectorForAccount: (
		accountId: string,
		connectorId: string,
		operation: {},
		options?: RequestInit,
	) => Promise<boolean>;
	deleteUserAssignment: (
		accountId: string,
		assignment: Omit<UserAssignment, 'domain'>,
		options?: RequestInit,
	) => Promise<unknown>;

	getAccountAssignment: (
		accountId: string,
		filter? : {},
		options?: RequestInit,
	) => Promise<any>;
	getAccountAudits: (
		accountId: string,
		pageNumber: number,
		options?: RequestInit,
	) => Promise<PagedResponse<AuditDetails>>;
	getAccountClassifierTypes: (
		accountId: string,
	) => Promise<ClassifierViewType[]>;
	getAccountClassifierViews: (accountId: string) => Promise<ClassifierView[]>;
	getAccountConfig: (
		accountId: string,
		options?: RequestInit,
	) => Promise<CodeusAccountConfig>;
	getAccountConnectors: (
		id: string,
		type?: DIConnectorType,
		options?: RequestInit,
	) => Promise<DIConnector[]>;
	getAccountConnectorsForSubscription: (
		id: string,
		subId:string,
		type?: DIConnectorType,
		options?: RequestInit,
	) => Promise<DIConnector[]>;
	
	getAccountTierState: (
		accountId: string,
		options?: RequestInit,
	) => Promise<CodeusAccountState>;
	getAccountUsers: (
		accountId: string,
		filter?: {
			type: 'email' | 'name';
			value: string;
		},
		options?: RequestInit,
	) => Promise<UserDetail[]>;
	getAccounts: (
		name?: string,
		options?: RequestInit,
	) => Promise<CodeusAccount>;
	updateAccount: (
		accountId: string,
		operation : {},
		options?: RequestInit,
	) => Promise<CodeusAccount>;
	createSupportDelegateToken: (
		accountId?:string,
		options?: RequestInit,
	) => Promise<SupportDelegate>;
	getSupportDelegateToken: (
		options?: RequestInit,
	) => Promise<SupportDelegate>;
	deleteSupportDelegateToken: (
		delegateId:string,
		options?: RequestInit,
	) => Promise<boolean>;
	deleteAccount: (
		id: string,
		body? : {},
		options?: RequestInit,
	) => Promise<CodeusAccount>;
	getDeleteAccJob: (
		id?: string,
		options?: RequestInit,
	) => Promise<AccDeleteJob>;
	cancelDeleteAccJob: (
		id?: string,
		options?: RequestInit,
	) => Promise<any>;
	getFeed: (feedId: string, options?: RequestInit) => Promise<Feed>;
	getFeedV2: (feedId: string, options?: RequestInit) => Promise<Feed>;
	getFeedSnapshot: (
		feedId: string,
		options?: RequestInit,
	) => Promise<Snapshot>;
	getAccountFeeds: (
		accountId: string,
		PageNumber?: number,
		PageSize?: number,
		Classifications?: Array<string>,
		options?: RequestInit,
	) => Promise<Feed[]>;
	getAccountESFeeds: (
		accountId: string,
		startDate:Date,
		endDate:Date,
		searchText?: string,
		searchField?:string,
		selectedFilters?: Array<string>,
		PageNumber?: number,
		PageSize?: number,
		sortBy?:string,
		sortOrder?:string,
		status?:string,
		options?: RequestInit,
	) => Promise<ESFeedResponse>;
	getClassifierCategories: (
		accountId: string,
		options?: RequestInit,
	) => Promise<ClassifierCategories>;
	getDefaultReport: (
		accountId: string,
		groupBy: string,
		sortOrder: string,
		absoluteDateRangeStart: Date,
		absoluteDateRangeEnd: Date,
		period: string,
		options?: RequestInit,
	) => Promise<any>;
	getReportList: (accountId: string, options?: RequestInit) => Promise<any>;
	addReport: (
		accountId: string,
		body?: {},
		options?: RequestInit,
	) => Promise<any>;
	updateReport: (
		accountId: string,
		reportId: string,
		body?: {},
		options?: RequestInit,
	) => Promise<any>;
	getReportById: (
		accountId: string,
		reportId: string,
		absoluteDateRangeStart: Date,
		absoluteDateRangeEnd: Date,
		period: string,
		options?: RequestInit,
	) => Promise<any>;
	deleteReportById: (
		accountId: string,
		reportId: string,
		options?: RequestInit,
	) => Promise<any>;
	getAccountAggregateESFeeds: (
		accountId: string,
		startDate:Date,
		endDate:Date,
		searchText?: string,
		searchField?:string,
		selectedFilters?: Array<string>,
		status?:string,
		options?: RequestInit,
	) => Promise<ESFeedResponse>;
	getAccountDownloadESFeeds: (
		accountId: string,
		startDate:Date,
		endDate:Date,
		searchText?: string,
		searchField?:string,
		selectedFilters?: Array<string>,
		sortBy?:string,
		sortOrder?:string,
		status?:string,
		options?: RequestInit,
	) => Promise<string>;
	getAccountDownloadESScans: (
		accountId: string,
		startDate:Date,
		endDate:Date,
		searchText?: string,
		searchField?:string,
		selectedFilters?: Array<string>,
		sortBy?:string,
		sortOrder?:string,
		options?: RequestInit,
	) => Promise<string>;
	getAccountAggregateESScans: (
		accountId: string,
		startDate:Date,
		endDate:Date,
		searchText?: string,
		searchField?:string,
		selectedFilters?: Array<string>,
		options?: RequestInit,
	) => Promise<ESFeedResponse>;
	getPolicyEngineFeeds: (
		accountId: string,
		searchText: string
	) => Promise<PolicyResponse>;
	getConsent: (
		accountId: string
	) => Promise<boolean>;
	updatePolicyEngineFeedPriorities: (
		accountId: string,
		reqPayload
	) => Promise<UpdatePriorityResponse>;
	updatePolicyFeed: (
		accountId: string,
		reqPayload,
		policyId
	) => Promise<UpdatePolicyResponse>;
	createPolicy: (
		accountId: string,
		reqPayload,
	) => Promise<CreatePolicyResponse>;
	getPolicyGroups: (
		accountId: string,
		query?: string
	) => Promise<GroupsApiResponse>;
	getPolicyUsers: (
		accountId: string,
		query?: string
	) => Promise<UsersDataResponse>;
	getPolicyClassifiers: (
		accountId: string
	) => Promise<ClassifiersResponse>;
	deletePolicyFeed: (
		accountId: string,
		policyId: string
	) => Promise<boolean>;
	submitRedactionFeedback: (
		previewUrl: string,
		reqPayload,
	) => Promise<any>;
	getFeedbackSubmittedData: (
		itemId: string,
		findingId: string
	) => Promise<any>;
	getAccountESScans: (
		accountId: string,
		startDate:Date,
		endDate:Date,
		searchText?: string,
		searchField?:string,
		selectedFilters?: Array<string>,
		PageNumber?: number,
		PageSize?: number,
		sortBy?:string,
		sortOrder?:string,
		options?: RequestInit,
	) => Promise<ESScanResponse>;

	getAccountDownloadESAudit: (
		accountId: string,
		startDate:Date,
		endDate:Date,
		searchText?: string,
		searchField?:string,
		selectedFilters?: Array<string>,
		sortBy?:string,
		sortOrder?:string,
		options?: RequestInit,
	) => Promise<string>;
	getAccountAggregateESAudit: (
		accountId: string,
		startDate:Date,
		endDate:Date,
		searchText?: string,
		searchField?:string,
		selectedFilters?: Array<string>,
		options?: RequestInit,
	) => Promise<ESFeedResponse>;
	getAccountESAudit: (
		accountId: string,
		startDate:Date,
		endDate:Date,
		searchText?: string,
		searchField?:string,
		selectedFilters?: Array<string>,
		PageNumber?: number,
		PageSize?: number,
		sortBy?:string,
		sortOrder?:string,
		options?: RequestInit,
	) => Promise<ESScanResponse>;
	getLoggedInUser: (options?: RequestInit) => Promise<User>;
	getAccountStats: (
		accountId: string,
		options?: RequestInit,
	) => Promise<CodeusAccountStats>;
	getUserAssignments: (
		userId: string,
		options?: RequestInit,
	) => Promise<UserAssignment[]>;
	getCompanies: (
		search:string,
		options?: RequestInit,
	) => Promise<Company[]>;
	getCompanyById: (
		accountId:string,
		options?: RequestInit,
	) => Promise<Company>;
	getCompanyAccounts: (
		companyId:string,
		options?: RequestInit,
	) => Promise<CodeusAccount[]>;
	addAccountToCompany:(
		companyId:string,
		accountName:string,
		options?: RequestInit,
	 ) => Promise<CodeusAccount[]>;
	getUserAccountAssignments: (
		userId: string,
		accountId:string,
		options?: RequestInit,
	) => Promise<UserAssignment[]>;
	getAllAccountAssignmentsForUser: (
		userId: string,
		options?: RequestInit,
	) => Promise<AllAccountAssignmentsForUser[]>;
	getUserInfo: (options?: RequestInit) => Promise<UserInfo>;
	getUserConnectors: (
		id?: string,
		type?: DIConnectorType,
		options?: RequestInit,
	) => Promise<DIConnector[]>;
	getUserAccounts: (
		id?: string,
		options?: RequestInit,
	) => Promise<CodeusAccountDetails[]>;
	getUserSubscriptions: (
		id?: string,
		options?: RequestInit,
	) => Promise<DISubscription[]>;
	saveAccountConfig: (
		accountId: string,
		params?: unknown,
		options?: RequestInit,
	) => Promise<CodeusAccountConfig>;
	updateFeed: (
		feedId: string,
		status: FeedStatus,
		reason: FeedStatusChangeReason,
		options?: RequestInit,
	) => Promise<Feed>;
	updateBulkFeed: (
		feedId: string[],
		status: FeedStatus,
		reason: FeedStatusChangeReason,
		options?: RequestInit,
	) => Promise<BulkFeedResponse>;
	updateTextFileClassifier: (
		accountId: string,
		viewId: string,
		operation: {},
		options?: RequestInit,
	) => Promise<ClassifierView>;
	updateRegexClassifier: (
		accountId: string,
		viewId: string,
		operation: {},
		options?: RequestInit,
	) => Promise<ClassifierView>;
	updateUserConfig: (
		userId: string,
		operation: {},
		options?: RequestInit,
	) => Promise<ClassifierView>;
	updateWordViewClassifier: (
		accountId: string,
		viewId: string,
		operation: {},
		options?: {},
	) => Promise<ClassifierView>;
	uploadTextFileView: (
		accountId: string,
		viewId: string,
		data: FormData,
	) => Promise<ClassifierView>;
	testRegex:(
		accountId:string,
		regex:string,
		testInput:string,
		displayName:string,
		tagName : string
	)=> Promise<TestClassifierResponse>;

}