import {
    Button,
    Checkbox, DataTableColumn,
    DataTableToolbar
} from '@barracuda-internal/bds-core';
import { FormControlLabel, TableCell } from '@material-ui/core';
import React, { useState } from 'react';
import DITable from 'src/components/DITable';
import useAuth from 'src/hooks/useAuth';
import { UserCell } from 'src/routes/dashboard/FeedRow';
import { UserDetail } from 'src/types/dataTypes';
import { roleMap } from './roleMap';

interface UserTableProps {
	isLoading: boolean;
	data: UserDetail[];
	onEditUser: (user: UserDetail) => void;
}

function UserTable(props: UserTableProps) {
	const { data, isLoading, onEditUser } = { ...props };

	function ControlCell({ dataItem }) {
		const handleClick = () => {
			onEditUser(dataItem);
		};

		return (
			<TableCell>
				<Button onClick={handleClick} variant='contained' color='primary'>Edit</Button>
			</TableCell>
		);
	}

	return (
		<DITable data={data} isLoading={isLoading}>
			<DataTableColumn
				field="user.email"
				title="User"
				cell={UserCell}
				editable={false}
			/>
			<DataTableColumn
				field="user.email"
				title="Email"
				editable={false}
			/>
			<DataTableColumn
				cell={AssignmentCell}
				field="user.assignments"
				title="Roles"
				editable={false}
			/>
			<DataTableColumn
				cell={ControlCell}
				field="user"
				title=" "
				editable={false}
			/>
		</DITable>
	);
}

function AssignmentCell({ dataItem: { assignments } }) {
	const [inEditMode, setInEditMod] = useState(false);
	const { auth } = useAuth();

	const accountRoles = assignments?.filter((a) =>
		a.domain.startsWith(`/accounts/${auth.accountDetails.account.id}`)
	);

	if (!inEditMode) {
		return (
			<TableCell>
				{accountRoles?.map((role) => (
					<p key={role.role}>{roleMap[role.role]}</p>
				))}
			</TableCell>
		);
	}

	return (
		<TableCell>
			{Object.entries(roleMap).map(([key, value]) => {
				return (
					<FormControlLabel
						key={key}
						label={value}
						control={
							<Checkbox
								checked={accountRoles.some(
									(a) => key === a.role
								)}
							/>
						}
					></FormControlLabel>
				);
			})}
		</TableCell>
	);
}
export default UserTable;
