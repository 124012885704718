import { AppBar } from '@material-ui/core';
import React, { useEffect } from 'react';
import { groupBy, forEach, find } from 'lodash';
import { AccountSwitcher } from '@barracuda-internal/bds-core';
import AddAccount from './AddAccountPopup';
import BCCAccountToolbar from 'src/components/BCC/BCCAccountToolbar';
import TierStatusRow from './TierStatusRow';
import useAuth from 'src/hooks/useAuth';
import { User } from 'src/types/dataTypes';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { useApiRequest } from 'src/hooks/useApi';
import useStyles from 'src/styles/di-theme';

export default function Header({
}: {
	logoutUrl: string;
	user: User;
}): React.ReactElement {

	const { api } = useAppToolchain();
	const { auth } = useAuth();
	const currentAccount = auth.accountDetails.account;
	const classes = useStyles();

	const [nestedAccounts, setNestedAccounts] = React.useState([]);
	const [selectedAccount, setSelectedAccount] = React.useState<any>(null);
	const [currentCompany, setCurrentCompany] = React.useState<any>(null);
	const [showAddAccount, setShowAddAccount] = React.useState<boolean>(false);
	const setAcc = (accounts) => {
		const bccAccounts =
			accounts.map((a) => ({
				id: a.account.id,
				name: a.account.name,
				companyId: a.account.companyId == null ? '0' : a.account.companyId,
				companyName: a.account.company == null ? null : a.account.company.name
			})) || [];
		const groupByCompany = groupBy(bccAccounts, 'companyId');
		let accountsWithSubAccounts = [];
		forEach(groupByCompany, (group, companyId) => {
			
			if (companyId == "0") {
				accountsWithSubAccounts.push({
					id: companyId,
					name: 'Unassigned Tenants',
					nestedAccounts: group
				})
			} else {
				accountsWithSubAccounts.push({
					id: companyId,
					name: group[0].companyName,
					nestedAccounts: group
				})

			}
		});
		setNestedAccounts(accountsWithSubAccounts);
		setSelectedAccount(find(bccAccounts, { id: auth.accountDetails.account.id }));
	}


	useEffect(() => {
		setAcc(auth.accounts);
		if (currentAccount.companyId != null) {
			setCurrentCompany(currentAccount.company)
		} else {
			setCurrentCompany({ id: '0', name: 'Unassigned Tenants' })
		}
	}, []);

	const handleAccountChange = (newAccountId: string) => {
		if (newAccountId === currentAccount.id) return;

		const newAccount = auth.accounts.find(
			(a) => a.account.id === newAccountId,
		);

		console.info('changing', newAccountId, newAccount);
		sessionStorage.clear();
		localStorage.setItem('lastActiveAccount', newAccountId);
		localStorage.removeItem('is_setup');
		location.href = '/';
	};

	const fetchAccounts = async () => {
		try {
			const accounts = await api.getUserAccounts(auth.user.id);
			setAcc(accounts);
		} catch (error) {
			console.error('Failed to fetch accounts:', error);
		}
	};

	return (
		<div>
			<AppBar position="static" color="inherit" elevation={0}>
				{!!auth ? (
					<div>
						{selectedAccount != null ? <AddAccount companyId={currentCompany.id} companyName={currentCompany.name} showAdd={showAddAccount} closePopup={(refresh) => {
							setShowAddAccount(false);
							if (refresh) {
								fetchAccounts()
							}
						}}></AddAccount> : null}

						<BCCAccountToolbar>
							{selectedAccount != null ? <div>

								<AccountSwitcher
									accounts={nestedAccounts}

									onSelectAccountCallback={(acc) => {
										handleAccountChange(acc.id)
									}}
									button2Text={auth.isFullAdmin() ? '+ Add Tenant' : ''}
									onButton2Click={() => {
										setShowAddAccount(true);
									}}
									initAccount={selectedAccount.id}
								/> </div> : null}

						</BCCAccountToolbar>
						{window.location.pathname !== '/welcome' ? <div className={
							classes['mt_4']
						}> <TierStatusRow></TierStatusRow> </div> : null}
					</div>
				) : null}
			</AppBar>
		</div>
	);
}

